import moment from "moment";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

export const TICKET_PREVIEW_VALUES = {
	BETSHOP_NAME: "Name",
	BETSHOP_ADDRESS: "Address",
	TERMINAL_NAME: "Name",
	TERMINAL_ADDRESS: "Address",
	TICKET_ID: 132141,
	DATETIME_PRINTED: "2020-12-12 12:00",
	CASHIER_ID: "Cashier",
	VALIDATION_DATE: moment().add(30, "days").set({ hour: 23, minute: 59 }).format(`${DATE_FORMAT} ${TIME_FORMAT}`),
	BET_TYPE: "Single",
	GAME_TYPE: "Football",
	DRAW_ID: 123456,
	START: "2020-12-12 12:00",
	ODDS: "1.55",
	STAKE: "500",
	POSSIBLE_WIN: "775",
	MAX_POSSIBLE_WIN: "1263",
	MAX_WIN_PER_TICKET: "15500",
	STATE: "Win",
	BARCODE_NUMBERS: "5 54 74 56 488 17 5 7"
};

export const TICKET_TYPE = {
	BET: 1,
	CANCEL: 2,
	PAYOUT: 3
};
