import { Fragment, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import TicketsComponent from "./tickets.component";

import { hasPermission } from "utils/permissions";
import { updateLocationHash, getHashValue, binaryToFlags } from "utils/common";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PARTNER_TYPE } from "constants/partner.constants";
import partnerTypePropType from "types/partner/partner.type";

/** CMS Betshop Ticket Page Component */
const TicketSettings = ({ globalPartnerId, globalPartners }) => {
	const { t } = useTranslation();

	const partnerType = globalPartners?.find(partner => partner.id === globalPartnerId)?.type
	

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	/** Is terminal type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf CurrencyAddComponent
	 */
	const isSSBT = () => partnerType && binaryToFlags(Object.values(PARTNER_TYPE), partnerType).includes(PARTNER_TYPE.SSBT);

	/** Function to make tab class name
	 * @function
	 * @description addes class "unsaved-tab" if tab has unsaved changes
	 * @param {string} key - tab key
	 * @returns {string}
	 * @memberOf TicketSettings
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-tab" : "");

	/** Fires when tab saved status changed
	 * @function
	 * @param {boolean} status - does tab have unsaved change
	 * @param {string} key - tab key
	 * @memberOf TicketSettings
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Fragment>
			<div className="dashboard-section">
				<Tabs
					animated={false}
					defaultActiveKey={getHashValue("tab")}
					onChange={(key) => updateLocationHash("tab=" + key)}
					destroyInactiveTabPane={true}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.menu.betshop_ticket")}</span>,
							children: <TicketsComponent isTerminal={false} onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						isSSBT()
							? (
								{
									key: "2",
									label: <span className={tabClassName("2")}>{t("pages.dashboard.menu.terminal_ticket")}</span>,
									children: <TicketsComponent isTerminal={true} onTabChange={(status) => changeTabSavedStatus(status, "2")} />
								}
							)
							: null
					]}
				/>
			</div>
		</Fragment>
	);
};

/** TicketSettings propTypes
 * PropTypes
 */
TicketSettings.propTypes = {
	/** Redux action to set the partner ticket logo */
	setLogo: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** Redux state property, represents the array of global partners  */
	globalPartners: PropTypes.arrayOf(partnerTypePropType),
};

const mapDispatchToProps = (dispatch) => ({
	setLogo: (logo) => {
		dispatch(setLogo(logo));
	}
});

const mapStateToProps = (state) => {
	return {
		globalPartnerId: state.partner.globalPartnerId,
		globalPartners: state.partner.globalPartners
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketSettings);
