import localStorageUtils from "utils/localStorage";
import sessionStorageUtils from "utils/sessionStorage";

import initialState from "../../initialState";

import {
	SET_GLOBAL_PARTNER_ID,
	SET_GLOBAL_PARTNERS,
	PARTNER_SAVE_ACTION_SUCCESS,
	PARTNER_ACTION_BEFORE,
	PARTNER_ACTION_FINISH,
	PARTNER_RESET,
	PARTNER_ACTION_SET_GENERAL_INFO,
	PARTNER_ACTION_SET_TERMINAL_CONFIGS,
	PARTNER_ACTION_SET_ONLINE_CONFIGS,
	PARTNER_ACTION_SET_ONLINE_RTP,
	PARTNER_ACTION_SET_RETAIL_CONFIGS,
	PARTNER_ACTION_SET_RETAIL_RTP,
	PARTNER_SET_SECURITY_PASSWORD_SETTINGS,
	PARTNER_SET_SECURITY_MULTIDEVICE_TOKEN_SETTINGS,
	PARTNER_SET_SECURITY_IP_RESTRICTION,
	PARTNER_CURRENCIES_BEFORE,
	PARTNER_CURRENCIES_FINISH,
	PARTNER_SET_CURRENCIES,
	PARTNER_ADD_CURRENCY,
	PARTNER_UPDATE_CURRENCY,
	PARTNER_DELETE_CURRENCY,
	PARTNER_AVAILABLE_CURRENCIES_BEFORE,
	PARTNER_AVAILABLE_CURRENCIES_FINISH,
	PARTNER_SET_AVAILABLE_CURRENCIES,
	PARTNER_LANGUAGES_BEFORE,
	PARTNER_LANGUAGES_FINISH,
	PARTNER_SET_LANGUAGES,
	PARTNER_ADD_LANGUAGE,
	PARTNER_UPDATE_LANGUAGE,
	PARTNER_DELETE_LANGUAGE,
	PARTNER_AVAILABLE_LANGUAGES_BEFORE,
	PARTNER_AVAILABLE_LANGUAGES_FINISH,
	PARTNER_SET_AVAILABLE_LANGUAGES,
	SET_PARTNER_LOGO,
	PARTNER_SET_SECURITY_AUTHENTICATION_SETTINGS,
	PARTNER_ACTION_SET_TERMINAL_RTP
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_GLOBAL_PARTNER_ID:
			if (payload.partnerId !== state.globalPartnerId) {
				localStorageUtils.remove("filters");
			}
			sessionStorageUtils.set("selected_partner_id", payload.partnerId);
			return {
				...state,
				globalPartnerId: payload.partnerId
			};
		case SET_GLOBAL_PARTNERS:
			return {
				...state,
				globalPartners: payload.partners.item2
			};
		case PARTNER_SAVE_ACTION_SUCCESS:
			return {
				...state,
				globalPartners: [payload.data, ...state.globalPartners]
			};
		case PARTNER_ACTION_BEFORE:
			return {
				...state,
				isLoading: true
			};
		case PARTNER_ACTION_FINISH:
			return {
				...state,
				isLoading: false
			};
		case PARTNER_ACTION_SET_GENERAL_INFO:
			return {
				...state,
				generalInfo: payload.info,
				globalPartners: state.globalPartners.map((p) => (p.id === payload.info.partnerId ? { ...p, type: payload.info.type } : p))
			};
		case PARTNER_ACTION_SET_TERMINAL_CONFIGS:
			return {
				...state,
				terminalSettings: {
					...state.terminalSettings,
					terminalConfigs: {
						...state.terminalSettings.terminalConfigs,
						...payload.configs
					}
				}
			};
		case PARTNER_ACTION_SET_TERMINAL_RTP:
			return {
				...state,
				terminalSettings: {
					...state.terminalSettings,
					terminalRTP: payload.rtp
				}
			};
		case PARTNER_ACTION_SET_ONLINE_CONFIGS:
			return {
				...state,
				onlineSettings: {
					...state.onlineSettings,
					onlineConfigs: payload.configs
				}
			};
		case PARTNER_ACTION_SET_ONLINE_RTP:
			return {
				...state,
				onlineSettings: {
					...state.onlineSettings,
					onlineRTP: payload.rtp
				}
			};
		case PARTNER_ACTION_SET_RETAIL_CONFIGS:
			return {
				...state,
				retailSettings: {
					...state.retailSettings,
					retailConfigs: payload.configs
				}
			};
		case PARTNER_ACTION_SET_RETAIL_RTP:
			return {
				...state,
				retailSettings: {
					...state.retailSettings,
					retailRTP: payload.rtp
				}
			};
		case PARTNER_SET_SECURITY_PASSWORD_SETTINGS:
			return {
				...state,
				securitySettings: {
					...state.securitySettings,
					passwordSettings: payload.settings
				}
			};
		case PARTNER_SET_SECURITY_AUTHENTICATION_SETTINGS:
			return {
				...state,
				securitySettings: {
					...state.securitySettings,
					authenticationSettings: payload.settings
				}
			};
		case PARTNER_SET_SECURITY_MULTIDEVICE_TOKEN_SETTINGS:
			return {
				...state,
				securitySettings: {
					...state.securitySettings,
					tokenMultidevice: payload.settings
				}
			};
		case PARTNER_SET_SECURITY_IP_RESTRICTION:
			return {
				...state,
				securitySettings: {
					...state.securitySettings,
					ipRestriction: payload.settings
				}
			};
		case PARTNER_CURRENCIES_BEFORE:
			return {
				...state,
				currency: {
					...state.currency,
					isLoading: true
				}
			};
		case PARTNER_CURRENCIES_FINISH:
			return {
				...state,
				currency: {
					...state.currency,
					isLoading: false
				}
			};
		case PARTNER_SET_CURRENCIES:
			return {
				...state,
				currency: {
					...state.currency,
					currencies: payload.currencies
				}
			};
		case PARTNER_ADD_CURRENCY:
			return {
				...state,
				currency: {
					...state.currency,
					currencies: [...state.currency.currencies, payload.currency]
				}
			};
		case PARTNER_UPDATE_CURRENCY:
			return {
				...state,
				currency: {
					...state.currency,
					currencies: state.currency.currencies.map((c) => (c.code === payload.currency.code ? payload.currency : { ...c, isDefault: payload.currency.isDefault ? false : c.isDefault }))
				}
			};
		case PARTNER_DELETE_CURRENCY:
			return {
				...state,
				currency: {
					...state.currency,
					currencies: state.currency.currencies.filter((c) => c.code !== payload.code)
				}
			};
		case PARTNER_AVAILABLE_CURRENCIES_BEFORE:
			return {
				...state,
				currency: {
					...state.currency,
					isAvailableLoading: true
				}
			};
		case PARTNER_AVAILABLE_CURRENCIES_FINISH:
			return {
				...state,
				currency: {
					...state.currency,
					isAvailableLoading: false
				}
			};
		case PARTNER_SET_AVAILABLE_CURRENCIES:
			return {
				...state,
				currency: {
					...state.currency,
					availableCurrencies: payload.currencies
				}
			};
		case PARTNER_LANGUAGES_BEFORE:
			return {
				...state,
				language: {
					...state.language,
					isLoading: true
				}
			};
		case PARTNER_LANGUAGES_FINISH:
			return {
				...state,
				language: {
					...state.language,
					isLoading: false
				}
			};
		case PARTNER_SET_LANGUAGES:
			return {
				...state,
				language: {
					...state.language,
					languages: payload.languages
				}
			};
		case PARTNER_ADD_LANGUAGE:
			return {
				...state,
				language: {
					...state.language,
					languages: [...state.language.languages, ...payload.language]
				}
			};
		case PARTNER_UPDATE_LANGUAGE:
			return {
				...state,
				language: {
					...state.language,
					languages: state.language.languages.map((l) => (l.code === payload.language.code ? payload.language : { ...l, isDefault: payload.language.isDefault ? false : l.isDefault }))
				}
			};
		case PARTNER_DELETE_LANGUAGE:
			return {
				...state,
				language: {
					...state.language,
					languages: state.language.languages.filter((l) => l.code !== payload.code)
				}
			};
		case PARTNER_RESET:
			return {
				...initialState.partner,
				globalPartnerId: state.globalPartnerId
			};
		case PARTNER_AVAILABLE_LANGUAGES_BEFORE:
			return {
				...state,
				language: {
					...state.language,
					isAvailableLoading: true
				}
			};
		case PARTNER_AVAILABLE_LANGUAGES_FINISH:
			return {
				...state,
				language: {
					...state.language,
					isAvailableLoading: false
				}
			};
		case PARTNER_SET_AVAILABLE_LANGUAGES:
			return {
				...state,
				language: {
					...state.language,
					availableLanguages: payload.languages
				}
			};
		case SET_PARTNER_LOGO:
			return {
				...state,
				retailSettings: {
					...state.retailSettings,
					retailConfigs: {
						...state.retailSettings.retailConfigs,
						logoId: payload.logoId
					}
				}
			};
		default:
			return state;
	}
};
