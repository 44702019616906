import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { PARTNER_ACTION_SET_TERMINAL_CONFIGS, PARTNER_ACTION_SET_TERMINAL_RTP } from "../../../actionTypes";

import { setPartnerActionBefore, setPartnerActionFinished, setPartnerSaveActionBefore, setPartnerSaveActionFinished } from "./partner.action";
import { getUserInfo } from "store/actions/dashboard/profile/userInfo.action";

const setPartnerActionSetTerminalConfigs = (configs) => ({
	type: PARTNER_ACTION_SET_TERMINAL_CONFIGS,
	payload: { configs }
});

const setPartnerActionSetOnlineRTP = (rtp) => ({
	type: PARTNER_ACTION_SET_TERMINAL_RTP,
	payload: { rtp }
});

export const getPartnerTerminalConfigs = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_TERMINAL_SETTINGS}`,
			method: Methods.GET
		})
			.then(({ data: { value: configs } }) => {
				dispatch(
					setPartnerActionSetTerminalConfigs({
						...configs
					})
				);
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerTerminalConfigs = (configs) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_TERMINAL_SETTINGS}`,
			method: Methods.POST,
			data: configs
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(
						setPartnerActionSetTerminalConfigs({
							...data.value
						})
					);
					dispatch(getUserInfo(true));
				}

				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};

export const getPartnerTerminalRTP = () => {
	return (dispatch) => {
		dispatch(setPartnerActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_TERMINAL_RTP}`,
			method: Methods.GET
		})
			.then(({ data: { value: rtp } }) => {
				dispatch(setPartnerActionSetOnlineRTP(rtp));
				dispatch(setPartnerActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerActionFinished());
			});
	};
};

export const savePartnerTerminalRTP = (rtp) => {
	return (dispatch) => {
		dispatch(setPartnerSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_TERMINAL_RTP}`,
			method: Methods.POST,
			data: rtp
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPartnerActionSetOnlineRTP(data.value));
				}

				dispatch(setPartnerSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setPartnerSaveActionFinished());
			});
	};
};