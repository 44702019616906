import { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { TICKET_PREVIEW_VALUES, TICKET_TYPE } from "constants/ticket.constants";
import { TERMINAL_TICKET_GENERAL_DETAILS, TICKET_GENERAL_DETAILS, TICKET_MARKET_DETAILS } from "constants/partner.constants";
import { binaryToFlags, buildPathToStaticFolderOfCDN } from "utils/common";

import ticketType from "types/partner/ticket.type";
import currencyType from "types/common/currency.type";

/** Ticket Component */
const Ticket = ({ ticket, isTerminal, type, logo, id, currency }) => {
	const { t } = useTranslation();

	const FIELD_CONSTANTS = isTerminal ? TERMINAL_TICKET_GENERAL_DETAILS : TICKET_GENERAL_DETAILS;
	const generalDetails = binaryToFlags(Object.values(FIELD_CONSTANTS), ticket.generalDetails);
	const marketSelection = binaryToFlags(Object.values(TICKET_MARKET_DETAILS), ticket.marketSelection);

	return (
		<div id={id} className="vs--print-wrapper">
			<div className="vs--print">
				<div className="vs--print-header">{logo ? <img src={logo} alt="logo" /> : <span>{t("pages.dashboard.tickets.view.header_logo")}</span>}</div>
				<div className="vs--print-header-sub">
					<div className="vs--print-header-sub-title">
						<i className="icon-virtual-games" />
						<h1>{t("common.virtualSports")}</h1>
					</div>
				</div>
				{(generalDetails.includes(FIELD_CONSTANTS.BETSHOP_NAME) ||
					generalDetails.includes(FIELD_CONSTANTS.BETSHOP_ADDRESS) ||
					generalDetails.includes(FIELD_CONSTANTS.TICKET_ID) ||
					generalDetails.includes(FIELD_CONSTANTS.PRINTED_DATETIME) ||
					generalDetails.includes(FIELD_CONSTANTS.CASHIER_ID) ||
					generalDetails.includes(FIELD_CONSTANTS.VALIDATION_DATE)) && (
					<div className="vs--print-info vs--print-info-dashed">
						{(generalDetails.includes(FIELD_CONSTANTS.BETSHOP_NAME) || generalDetails.includes(FIELD_CONSTANTS.TERMINAL_NAME)) && (
							<div className="vs--print-info-row">
								<span>{t(`pages.dashboard.tickets.view.${isTerminal ? "terminal_name" : "betshop_name"}`)}</span>
								<span>{isTerminal ? TICKET_PREVIEW_VALUES.TERMINAL_NAME : TICKET_PREVIEW_VALUES.BETSHOP_NAME}</span>
							</div>
						)}
						{(generalDetails.includes(FIELD_CONSTANTS.BETSHOP_ADDRESS) || generalDetails.includes(FIELD_CONSTANTS.TERMINAL_ADDRESS)) && (
							<div className="vs--print-info-row">
								<span>{t("pages.dashboard.tickets.view.betshop_address")}</span>
								<span>{isTerminal ? TICKET_PREVIEW_VALUES.TERMINAL_ADDRESS : TICKET_PREVIEW_VALUES.BETSHOP_ADDRESS}</span>
							</div>
						)}
						{generalDetails.includes(FIELD_CONSTANTS.TICKET_ID) && (
							<div className="vs--print-info-row">
								<span>{t("pages.dashboard.tickets.view.ticket_id")}</span>
								<span>{TICKET_PREVIEW_VALUES.TICKET_ID}</span>
							</div>
						)}
						{generalDetails.includes(FIELD_CONSTANTS.PRINTED_DATETIME) && (
							<div className="vs--print-info-row">
								<span>{t("pages.dashboard.tickets.view.date_time_printed")}</span>
								<span>{TICKET_PREVIEW_VALUES.DATETIME_PRINTED}</span>
							</div>
						)}
						{!isTerminal && generalDetails.includes(FIELD_CONSTANTS.CASHIER_ID) && (
							<div className="vs--print-info-row">
								<span>{t("pages.dashboard.tickets.view.cashier")}</span>
								<span>{TICKET_PREVIEW_VALUES.CASHIER_ID}</span>
							</div>
						)}
						{generalDetails.includes(FIELD_CONSTANTS.VALIDATION_DATE) && (
							<div className="vs--print-info-row">
								<span>{t("pages.dashboard.tickets.view.valid_till")}</span>
								<span>{TICKET_PREVIEW_VALUES.VALIDATION_DATE}</span>
							</div>
						)}
					</div>
				)}

				{(marketSelection.includes(TICKET_MARKET_DETAILS.TEAM_NAMES) ||
					marketSelection.includes(TICKET_MARKET_DETAILS.GAME_TYPE) ||
					marketSelection.includes(TICKET_MARKET_DETAILS.DRAW_ID) ||
					marketSelection.includes(TICKET_MARKET_DETAILS.START_TIME) ||
					marketSelection.includes(TICKET_MARKET_DETAILS.MARKETS) ||
					marketSelection.includes(TICKET_MARKET_DETAILS.STAKE) ||
					marketSelection.includes(TICKET_MARKET_DETAILS.POSSIBLE_WIN)) && (
					<div className="vs--print-info">
						<div className="vs--print-info-bet">
							{marketSelection.includes(TICKET_MARKET_DETAILS.TEAM_NAMES) && (
								<div className="vs--print-info-row">
									<b>{t("pages.dashboard.tickets.view.teams")}</b>
								</div>
							)}
							{marketSelection.includes(TICKET_MARKET_DETAILS.GAME_TYPE) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.game_type")}</span>
									<span>{TICKET_PREVIEW_VALUES.GAME_TYPE}</span>
								</div>
							)}
							{marketSelection.includes(TICKET_MARKET_DETAILS.DRAW_ID) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.event_id")}</span>
									<span>{TICKET_PREVIEW_VALUES.DRAW_ID}</span>
								</div>
							)}
							{marketSelection.includes(TICKET_MARKET_DETAILS.START_TIME) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.start")}</span>
									<span>{TICKET_PREVIEW_VALUES.START}</span>
								</div>
							)}

							{marketSelection.includes(TICKET_MARKET_DETAILS.MARKETS) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.market")}</span>
									<span>{t("pages.dashboard.tickets.view.selection")}</span>
								</div>
							)}
							{marketSelection.includes(TICKET_MARKET_DETAILS.MARKETS) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.odds")}</span>
									<span>{TICKET_PREVIEW_VALUES.ODDS}</span>
								</div>
							)}
							{marketSelection.includes(TICKET_MARKET_DETAILS.STAKE) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.stake")}</span>
									<span>{`${TICKET_PREVIEW_VALUES.STAKE} ${currency.code}`}</span>
								</div>
							)}
							{marketSelection.includes(TICKET_MARKET_DETAILS.POSSIBLE_WIN) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.possibleWin")}</span>
									<span>{`${TICKET_PREVIEW_VALUES.POSSIBLE_WIN} ${currency.code}`}</span>
								</div>
							)}
							{marketSelection.includes(TICKET_MARKET_DETAILS.STATE) && (
								<div className="vs--print-info-row">
									<span>{t("pages.dashboard.tickets.view.status")}</span>
									<span>{TICKET_PREVIEW_VALUES.STATE}</span>
								</div>
							)}
						</div>
					</div>
				)}

				{(marketSelection.includes(TICKET_MARKET_DETAILS.BET_TYPE) || marketSelection.includes(TICKET_MARKET_DETAILS.STAKE) || marketSelection.includes(TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN)) && (
					<div className="vs--print-info vs--print-info-dashed">
						{marketSelection.includes(TICKET_MARKET_DETAILS.BET_TYPE) && (
							<div className="vs--print-info-row">
								<span>{t("pages.dashboard.tickets.view.bet_type")}</span>
								<b>{TICKET_PREVIEW_VALUES.BET_TYPE}</b>
							</div>
						)}
						{marketSelection.includes(TICKET_MARKET_DETAILS.STAKE) && (
							<div className="vs--print-info-row">
								<span>{type === TICKET_TYPE.BET ? t("pages.dashboard.tickets.view.stake") : type === TICKET_TYPE.CANCEL ? t("pages.dashboard.tickets.view.cancellation") : type === TICKET_TYPE.PAYOUT ? t("pages.dashboard.tickets.view.payout") : null}</span>
								<b>{`${TICKET_PREVIEW_VALUES.STAKE} ${currency.code}`}</b>
							</div>
						)}
						{marketSelection.includes(TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN) && (
							<div className="vs--print-info-row">
								<span>{t("pages.dashboard.tickets.view.max_possible_win")}</span>
								<b>{`${TICKET_PREVIEW_VALUES.MAX_POSSIBLE_WIN} ${currency.code}`}</b>
							</div>
						)}
					</div>
				)}

				{marketSelection.includes(TICKET_MARKET_DETAILS.MAX_WIN_PER_TICKET) && (
					<div className="vs--print-info">
						<div className="vs--print-info-row">
							<span>{t("pages.dashboard.tickets.view.max_win_per_ticket")}</span>
							<b>{`${TICKET_PREVIEW_VALUES.MAX_WIN_PER_TICKET} ${currency.code}`}</b>
						</div>
					</div>
				)}
				<div className="vs--print-footer">
					<div className="vs--print-footer-inner">
						<div className="vs--print-footer-barcode">
							{ticket.enableBarcodePrinting && (
								<Fragment>
									<img src={buildPathToStaticFolderOfCDN("admin-images/barcode.svg")} alt="barcode" />
								</Fragment>
							)}
						</div>
						<div className="vs--print-footer-qr">{ticket.enableQRPrinting && <img id="qr-image" src={buildPathToStaticFolderOfCDN("admin-images/qr.svg")} alt="qr" />}</div>
						<span className="vs--print-footer-custom-text">{ticket.customText || t("pages.dashboard.tickets.view.your_custom_text")}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

/** Ticket propTypes
 * PropTypes
 */
Ticket.propTypes = {
	/** The ticket data */
	ticket: ticketType,
	/** The ticket type */
	type: PropTypes.oneOf(Object.values(TICKET_TYPE)),
	/** The ticket logo url */
	logo: PropTypes.string,
	/** The DOM id attribute of the element */
	id: PropTypes.string,
	/** Redux state property, current user currency */
	currency: currencyType
};

const mapStateToProps = (state) => {
	return {
		currency: state.profile.userInfo.currency
	};
};

export default connect(mapStateToProps, null)(Ticket);
