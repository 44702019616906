import { Fragment, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Tabs } from "antd";

import BetTicketComponent from "./sections/betTicket";
import CancelTicketComponent from "./sections/cancelTicket";
import PayoutTicketComponent from "./sections/payoutTicket";

import { hasPermission } from "utils/permissions";
import { updateLocationHash, getHashValue } from "utils/common";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { setLogo } from "store/actions/dashboard/cms/tickets/ticket.action";

/** CMS Betshop Ticket Page Component */
const TicketsComponent = ({ isTerminal, setLogo }) => {
	const { t } = useTranslation();

	const [unsavedTabs, setUnsavedTabs] = useState([]);

	useEffect(() => () => setLogo(null), []);

	/** Function to make subtab class name
	 * @function
	 * @description addes class "unsaved-subtab" if subtab has unsaved changes
	 * @param {string} key - subtab key
	 * @returns {string}
	 * @memberOf TicketsComponent
	 */
	const tabClassName = (key) => (unsavedTabs.indexOf(key) > -1 ? "unsaved-subtab" : "");

	/** Fires when subtab saved status changed
	 * @function
	 * @param {boolean} status - does subtab have unsaved change
	 * @param {string} key - subtab key
	 * @memberOf TicketsComponent
	 */
	const changeTabSavedStatus = (status, key) => {
		if (status && unsavedTabs.indexOf(key) === -1) {
			setUnsavedTabs([...unsavedTabs, key]);
		} else if (!status) {
			setUnsavedTabs(unsavedTabs.filter((t) => t !== key));
		}
	};

	return (
		<Fragment>
			<Tabs
					animated={false}
					defaultActiveKey={getHashValue("subtab")}
					onChange={(key) => updateLocationHash("subtab=" + key, true)}
					className="sub-tabs"
					destroyInactiveTabPane={true}
					items={[
						{
							key: "1",
							label: <span className={tabClassName("1")}>{t("pages.dashboard.partners.retail_settings.tabs.bet_ticket")}</span>,
							children: <BetTicketComponent isTerminal={isTerminal} onTabChange={(status) => changeTabSavedStatus(status, "1")} />
						},
						...(
							isTerminal
								? []
								: [
									{
										key: "2",
										label: <span className={tabClassName("2")}>{t("pages.dashboard.partners.retail_settings.tabs.cancellation_ticket")}</span>,
										children: <CancelTicketComponent onTabChange={(status) => changeTabSavedStatus(status, "2")} />
									},
									{
										key: "3",
										label: <span className={tabClassName("3")}>{t("pages.dashboard.partners.retail_settings.tabs.payout_ticket")}</span>,
										children: <PayoutTicketComponent onTabChange={(status) => changeTabSavedStatus(status, "3")} />
									}
								]
						)
					]}
				/>
		</Fragment>
	);
};

/** TicketsComponent propTypes
 * PropTypes
 */
TicketsComponent.propTypes = {
	/** Redux action to set the partner ticket logo */
	setLogo: PropTypes.func,
	/** React property, is ticket for terminal */
	isTerminal: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	setLogo: (logo) => {
		dispatch(setLogo(logo));
	}
});

export default connect(null, mapDispatchToProps)(TicketsComponent);
