import OvenPlayer from 'ovenplayer';
import VideoPlayer from "../player";
import volumeButtonTemplate from "ovenplayer/src/js/view/components/controls/volumeButtonTemplate"
import playButtonTemplate from "ovenplayer/src/js/view/components/controls/playButtonTemplate"
import fullScreenButtonTemplate from 'ovenplayer/src/js/view/components/controls/fullScreenButtonTemplate';
import settingButtonTemplate from "ovenplayer/src/js/view/components/controls/settingButtonTemplate"
import { PLAYER_UNKNWON_ERROR, STATE_AD_PLAYING, STATE_IDLE, STATE_LOADING, STATE_PAUSED, STATE_PLAYING } from 'ovenplayer/src/js/api/constants';
import { HIDE_CONTROLS_CLASS, PLAYER_SIZES } from './contants';
import "./playerStyles.scss"

export default class OvenPlayerVideoPlayer extends VideoPlayer {

	_resizeObserver = null
	_autoHideTimer = null
	_settingIsOpen = false

	constructor(streamConfiguration, options, globalVariables) {
		super(streamConfiguration, options, globalVariables);
	}

	setStreamConfiguration(configuration) {
		this.streamConfiguration = configuration;
	}

	makeConfigs() {
		return {
			...this.streamConfiguration,
			controls: false,
			autoStart: true,
			mute: true,
			iOSFakeFullScreen: false,
			expandFullScreenUI: false
		}
	}

	init() {
		if (this.initialized) return;
		if (this.streamConfiguration === null || this.streamConfiguration === undefined) return this.setError(true);
		if (!this.streamConfiguration || !this.streamConfiguration.sources || this.streamConfiguration.sources.length === 0) return this.setError(true);
		if (!this.options.elementID) return this.setError(true);
		const container = document.getElementById(this.options.elementID)
		if (!container) return this.setError(true);
		this.domElements = this._predefiningDOM()
		container.append(this.domElements.playerWrapper)
		console.log("OvenPlayer init");

		this.player = OvenPlayer.create(this.domElements.playerElement, this.makeConfigs());

		this._handleEvents()

		this.initialized = true;
	}

	destroy() {
		console.log("OvenPlayer destroy");
		this._turnOffResizeListener()
		this.domElements?.playerWrapper && this.domElements.playerWrapper.remove()
		this.player && this.player.stop();
		this.player = null;
		this.options.onDestroy && this.options.onDestroy();
		this.initialized = false;
	}

	mute() {
		if (this.player) {
			this.player.setVolume(0)
			this.player.setMute(true);
		}
		if (this.options) {
			this.options.onMute && this.options.onMute(true);
		}
	}

	unmute() {
		if (this.player) {
			this.player.setVolume(100)
			this.player.setMute(false);
		}

		if (this.options) {
			this.options.onMute && this.options.onMute(false);
		}
	}

	setPlayerVolume(volume) {
		this.player.setVolume(volume);
	}

	getPlayerVolume() {
		return this.player.getVolume()
	}

	getPlayerMuted() {
		return this.player.getMute()
	}

	_predefiningDOM() {
		const playerWrapper = document.createElement("div");
		playerWrapper.classList.add("vs-oven-player-wrapper");

		const playerContainer = document.createElement("div");
		playerContainer.classList.add("vs-oven-player-container");
		playerWrapper.appendChild(playerContainer);

		const playerMonitor = document.createElement("div");
		playerMonitor.classList.add("vs-oven-player-monitor");
		playerContainer.appendChild(playerMonitor);

		const playerElement = document.createElement("div");
		playerMonitor.appendChild(playerElement);

		const playerOverlay = document.createElement("div");
		playerOverlay.classList.add("vs-oven-player-overlay");
		playerContainer.appendChild(playerOverlay);

		const playerControls = document.createElement("div");
		playerControls.classList.add("vs-oven-player-controls");
		playerWrapper.appendChild(playerControls);

		const playerControlsGroup1 = document.createElement("div");
		const playerControlsGroup2 = document.createElement("div");
		playerControlsGroup1.classList.add("vs-oven-player-controls-group");
		playerControlsGroup2.classList.add("vs-oven-player-controls-group");

		playerControls.appendChild(playerControlsGroup1)
		playerControls.appendChild(playerControlsGroup2)

		playerControlsGroup1.insertAdjacentHTML("afterbegin", volumeButtonTemplate())
		playerControlsGroup1.insertAdjacentHTML("afterbegin", playButtonTemplate())
		playerControlsGroup2.insertAdjacentHTML("afterbegin", fullScreenButtonTemplate())
		playerControlsGroup2.insertAdjacentHTML("afterbegin", settingButtonTemplate())

		const playController = playerControlsGroup1.querySelector(".op-play-controller")
		const volumeController = playerControlsGroup1.querySelector(".op-volume-controller")
		const settingsController = playerControlsGroup2.querySelector(".op-setting-button")
		const fullScreenController = playerControlsGroup2.querySelector(".op-fullscreen-button")

		return {
			playerWrapper,
			playerContainer,
			playerMonitor,
			playerElement,
			playerOverlay,
			playerControls,
			playerControlsGroup1,
			playerControlsGroup2,
			playController,
			volumeController,
			settingsController,
			fullScreenController
		}
	}

	_handleEvents() {

		this.player.on('stateChanged', (event) => {
			const state = event.newstate;
			const playIcon = this.domElements.playController.querySelector(".op-play")
			const pauseIcon = this.domElements.playController.querySelector(".op-pause")
			switch (state) {
				case STATE_PLAYING:
					playIcon.style.display = "none"
					pauseIcon.style.display = "block"
					this.onPlay()
					this._turnOnResizeListener(this.domElements.playerMonitor.querySelector(".ovenplayer video"))
					break;
				case STATE_PAUSED:
					playIcon.style.display = "block"
					pauseIcon.style.display = "none"
					this.onPause()
					break;
				default:
					break;
			}
		});

		this.player.on("ready", () => {
			this.onPlayerReady();
			this.addEventListeners();
			this.mute()
			this.player.setAutoQuality(true)
			const sources = this.streamConfiguration.sources || [];
			if (sources.length > 0) {
				this.player?.setCurrentSource(0);
				this.player?.play()
			}
		});

		this.player.on("error", (error) => {
			console.error("Error in player:", error);

			const currentSourceIndex = this.player.getCurrentSource();

			// Check if the current source is inaccessible (e.g., WebRTC source is unavailable)
			if (error.code && (error.code === 1006 || error.code === 2002 || error.code === 4001)) {
				console.log("Current source failed with error code:", error.code);
				const sources = this.streamConfiguration.sources || [];
				// Check if a next source exists
				if (currentSourceIndex + 1 < sources.length) {
					console.log("Switching to next source...");
					this.player.setCurrentSource(currentSourceIndex + 1); // Switch to the next source
					this.player.play(); // Start playback
				} else {
					const condition = error.code >= PLAYER_UNKNWON_ERROR;
					setTimeout(() => {
						this.reload(condition ? "FATAL" : "EXPIRED");
					}, 400);
				}
			}
		});

		this.player.on("volumeChanged", ({ volume, mute }) => {
			this.onVolumeChange(volume);
			const volumeMax = this.domElements.volumeController.querySelector(".op-volume-max")
			const volumeMute = this.domElements.volumeController.querySelector(".op-volume-mute")
			if (mute) {
				volumeMute.style.display = "block"
				volumeMax.style.display = "none"
			} else {
				volumeMute.style.display = "none"
				volumeMax.style.display = "block"
			}
		});

		this.player.on("fullscreenChanged", (isCompressed) => {
			const fullscreenExpand = this.domElements.fullScreenController.querySelector(".op-fullscreen-expand")
			const fullscreenCompress = this.domElements.fullScreenController.querySelector(".op-fullscreen-compress")
			if (isCompressed) {
				fullscreenExpand.style.display = "block"
				fullscreenCompress.style.display = "none"
			} else {
				fullscreenExpand.style.display = "none"
				fullscreenCompress.style.display = "block"
			}
		});
	}

	addEventListeners() {
		if (!this.player) { return; }

		this.domElements.playerMonitor.addEventListener("dblclick", this.toggleFullScreen.bind(this))
		this.domElements.fullScreenController.addEventListener("click", this.toggleFullScreen.bind(this))
		this.domElements.playController.addEventListener("click", this.togglePlayPause.bind(this))
		this.domElements.volumeController.addEventListener("click", this.toggleMuteUnmute.bind(this))
		this.domElements.settingsController.addEventListener("click", this.toggleSettings.bind(this))
		const ovenPlayerElement = this.domElements.playerMonitor.querySelector(".ovenplayer");
		ovenPlayerElement.addEventListener("contextmenu", (e) => {
			e.stopPropagation()
		}, { capture: true })


		const eventCommonHandler = (event, hideCallback, showCallback) => {
			if (!this.player) { return; }
			if (this._settingIsOpen) {
				return this._setHide(false);
			}
			const playerState = this.player.getState()
			const screenSize = this._getScreenSize();
			event.preventDefault();

			if (playerState === STATE_PLAYING || playerState === STATE_IDLE || playerState === STATE_LOADING || (playerState === STATE_AD_PLAYING && screenSize === PLAYER_SIZES.X_SMALL)) {
				if (typeof hideCallback === "function") {
					hideCallback()
				}
			} else {
				if (typeof showCallback === "function") {
					showCallback()
				}
			}
		}

		const events = {
			//For iOS safari
			"touchstart": (event) => {
				eventCommonHandler(event, () => this._setHide(false, true), () => this._setHide(false))
			},
			"mouseenter": (event) => {
				eventCommonHandler(event, () => this._setHide(false, true), () => this._setHide(false))
			},
			"mousemove": (event) => {
				eventCommonHandler(event, () => this._setHide(false, true), () => this._setHide(false))
			},
			"mouseleave": (event) => {
				eventCommonHandler(event, () => this._setHide(true), null)
			}
		};

		Object
			.entries(events)
			.forEach(([key, listener]) => {
				this.domElements.playerWrapper.addEventListener(key, listener)
			})
	}

	toggleFullScreen() {
		if (Boolean(document.fullscreenElement)) {
			// Exit full-screen if currently in full-screen mode
			return this._exitFullScreen();
		}
		// Enter full-screen if not in full-screen mode
		return this._enterFullScreen(this.domElements.playerWrapper);
	}

	togglePlayPause() {
		const state = this.player.getState()
		switch (state) {
			case STATE_PLAYING:
				this.player.pause()
				break;
			case STATE_PAUSED:
			case STATE_IDLE:
				this.player.play()
				break;
			default:
				break;
		}
	}

	toggleMuteUnmute() {
		if (this.player.getMute()) {
			this.player.setVolume(100)
		} else {
			this.player.setVolume(0)
		}
	}

	toggleSettings() {
		if (this._settingIsOpen) {
			const panel = this.domElements.playerControls.querySelector(".op-setting-panel")
			panel?.remove()
			this._settingIsOpen = false
			return
		}
		const checkedSymbol = "&#10004;";
		const autoQuality = (`
			<div class="op-setting-item" op-panel-type="quality" op-data-value="AUTO">
				<span class="op-setting-item-checked ${this.player.isAutoQuality() ? "op-show" : ""}">${checkedSymbol}</span>
				<span class="op-setting-item-title">AUTO</span>
			</div>
		`);

		const sortedQualityLevels = [...this.player.getQualityLevels()].sort((next, prev) => {
			return next.height < prev.height ? 0 : -1
		})

		const levels = sortedQualityLevels.map((qualityLevel) => {
			return (
				`
					<div class="op-setting-item" op-panel-type="quality" op-data-value="${qualityLevel.index}">
						<span class="op-setting-item-checked ${this.player.getCurrentQuality() === qualityLevel.index ? "op-show" : ""}">${checkedSymbol}</span>
						<span class="op-setting-item-title">${qualityLevel.label}</span>
					</div>
				`
			)
		}).join("");

		const qualityPanelTemplate = `
			<div class="op-setting-panel" style="max-height: 369px">
				<div class="op-setting-title-container">
					<div class="op-setting-title" tabindex="0">
						<span class="op-setting-title-previcon"></span>
						<span class="op-setting-title-title">Quality</span>
						</div></div>
						<div class="op-setting-item-container">
							${autoQuality}
							${levels}
						</div>
					</div>
				</div>
			</div>
		`;

		this._settingIsOpen = true;
		this.domElements.playerControls.insertAdjacentHTML("beforeend", qualityPanelTemplate)
		const panel = this.domElements.playerControls.querySelector(".op-setting-panel")
		const items = panel.querySelectorAll(".op-setting-item")
		items.forEach(item => {
			item.addEventListener("click", () => {
				const value = item.getAttribute("op-data-value")
				if (value === "AUTO") {
					this.player.setAutoQuality(!this.player.isAutoQuality());
				} else {
					this.player.setCurrentQuality(parseInt(value));
				}
				panel.remove()
				this._settingIsOpen = false;
			})
		})
	}

	_setHide(hide, autoHide) {

		if (!this.domElements || !this.domElements.playerWrapper) {
			return
		}

		const playerRoot = this.domElements.playerWrapper

		if (this._autoHideTimer) {
			clearTimeout(this._autoHideTimer);
			this._autoHideTimer = null;
		}

		if (hide) {
			playerRoot.classList.add(HIDE_CONTROLS_CLASS);
		} else {
			playerRoot.classList.remove(HIDE_CONTROLS_CLASS);
			if (autoHide) {
				this._autoHideTimer = setTimeout(function () {
					playerRoot.classList.add(HIDE_CONTROLS_CLASS);
				}, 3000);
			}
		}
	}

	_getScreenSize() {
		if (!this.domElements) {
			return null
		}

		if (!this.domElements.playerMonitor) {
			return null
		}

		const ovenPlayerElement = this.domElements.playerMonitor.querySelector(".ovenplayer");

		if (!ovenPlayerElement) {
			return null
		}

		return Object.values(PLAYER_SIZES).find((value) => ovenPlayerElement.classList.contains(value)) ?? null
	}

	_exitFullScreen() {
		if (document.exitFullscreen) {
			return document.exitFullscreen();
		}
		if (document.mozCancelFullScreen) { // Firefox
			return document.mozCancelFullScreen();
		}
		if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
			return document.webkitExitFullscreen();
		}
		if (document.msExitFullscreen) { // IE/Edge
			return document.msExitFullscreen();
		}
		return Promise.reject()
	}

	_enterFullScreen(element) {
		const callback = this.onFullscreen.bind(this)
		if (element.requestFullscreen) {
			return element.requestFullscreen().then(callback);
		}
		if (element.mozRequestFullScreen) { // Firefox
			return element.mozRequestFullScreen().then(callback);
		}
		if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
			return element.webkitRequestFullscreen().then(callback);
		}
		if (element.msRequestFullscreen) { // IE/Edge
			return element.msRequestFullscreen().then(callback);
		}
		return Promise.reject()
	}

	_turnOffResizeListener() {
		this._resizeObserver?.disconnect()
		this._resizeObserver = null;
	}

	_turnOnResizeListener(target) {
		this._resizeOverlay(target);
		if (this._resizeObserver) {
			return
		}
		this._resizeObserver = new ResizeObserver(() => this._resizeOverlay(target));

		this._resizeObserver.observe(target);
	}

	_resizeOverlay(target) {
		const equals = {
			height: "100%",
			width: "100%",
		};

		if (target.videoHeight === 0 && target.videoWidth === target.videoHeight) {
			this.domElements.playerOverlay.style.height = equals.height;
			this.domElements.playerOverlay.style.width = equals.width;
			this.domElements.playerOverlay.style.aspectRatio = "1 / 1";
			return;
		}

		const fullWidth = {
			height: "initial",
			width: "100%",
		};

		const fullHeight = {
			height: "100%",
			width: "initial",
		};

		const boundingClientRect = target.getBoundingClientRect()

		const vW = boundingClientRect.width;
		const vH = boundingClientRect.height;
		const oW = window.outerWidth;
		const oH = window.outerHeight;

		const sizes = (
			vH > vW || oH >= oW
				? fullWidth
				: fullHeight
		)

		this.domElements.playerOverlay.style.height = sizes.height;
		this.domElements.playerOverlay.style.width = sizes.width;

		this.domElements.playerOverlay.style.aspectRatio = target.videoWidth / target.videoHeight;
	};
}