import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spin from "antd/lib/spin";
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import Switch from "antd/lib/switch";
import Button from "antd/lib/button";
import Select from "antd/lib/select";

const { Item: FormItem } = Form;
import { useTranslation } from "react-i18next";

import {
	getPartnerTerminalConfigs,
	savePartnerTerminalConfigs
} from "store/actions/dashboard/partners/terminalSettings.action";

import { copyToClipboard, isNullish } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { URL_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { REDEMPTION_DATE_VALUES } from "constants/common.constants";
import NumericInput from "components/common/numericInput";
import { COMBO_BOOST_STEP_FORM_NAMES, COMBO_BOOST_STEPS, COMBO_BOOST_STEPS_TRANSLATIONS } from "constants/bet.constants";

const comboBoostStepsValues = Object.values(COMBO_BOOST_STEPS)

const FORM_FIELDS = {
	SECRET_KEY: "secret",
	ENDPOINT_URL: "endpointUrl"
}

const getTerminalConfigs = (state) => state.partner.terminalSettings.terminalConfigs;
const getIsTerminalSettingsLoading = (state) => state.partner.isLoading;
const getIsTerminalSettingsSaving = (state) => state.partner.isSaving;

/** Terminal Tab Component */
const TerminalSettingsComponent = ({ onTabChange }) => {
	const isLoading = useSelector(getIsTerminalSettingsLoading);
	const isSaving = useSelector(getIsTerminalSettingsSaving);
	const terminalConfigs = useSelector(getTerminalConfigs);

	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	const [isFormTouched, setIsFormTouched] = useState(false);

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_TERMINAL_CONFIGURATION, action: PERMISSION_ACTION.MODIFY });

	const dispatch = useDispatch();

	const handleForm = () => {
		validateFields()
			.then((data) => {
				const boostFactors = comboBoostStepsValues.reduce((acc, comboBoostStep) => {
					acc[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]] = Number(data.comboBoostSettings.boostFactors[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]]) || null;
					const resetCondition = (
						comboBoostStepsValues
							.filter(value => value < comboBoostStep)
							.some(value => 100 === (Number(data.comboBoostSettings.boostFactors[value]) || 0))
					)
					if (resetCondition) {
						acc[COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]] = null;
					}
					return acc
				}, {})
				dispatch(savePartnerTerminalConfigs({
					...data,
					comboBoostSettings: {
						...data.comboBoostSettings,
						minOddFactor: Number(data.comboBoostSettings.minOddFactor) || 0,
						boostFactors: boostFactors
					}
				}));
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		dispatch(getPartnerTerminalConfigs());
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({ ...terminalConfigs });
	}, [terminalConfigs]);

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin vs--pl-12 vs--pr-12 vs--pt-12">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				onValuesChange={(changed, formValues) => {
					setIsFormTouched(isFormChanged(
						{
							...formValues,
							comboBoostSettings: {
								...formValues.comboBoostSettings,
								boostFactors: {
									...formValues.comboBoostSettings.boostFactors
								}
							}
						},
						{
							...terminalConfigs,
							comboBoostSettings: {
								...terminalConfigs.comboBoostSettings,
								boostFactors: {
									...terminalConfigs.comboBoostSettings.boostFactors
								}
							}
						}
					))
				}}
			>
				<div className="dashboard-section-content">
					<div className="terminal-settings vs--flex vs--flex-col">
						<Row>
							<Col span={12}>
								<Card
									className="terminal-settings-endpoint-details"
									title={(
										<Row align="middle">
											<div className="inline-form-item form-switcher form-item-without-margin">
												<label className="vs--ml-4">{t("pages.dashboard.partners.terminal_settings.retailIntegrationDetails")}</label>
											</div>
										</Row>
									)}
								>
									<Row gutter={[16, 0]} className="terminal-settings-endpoint-details-content">
										<Col span={12}>
											<FormItem
												label={`${t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")}`}
												name={FORM_FIELDS.SECRET_KEY}
											>
												<Input
													disabled
													placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")}`}
													className="form-input-with-copy"
												/>
											</FormItem>
											<Tooltip title={t("common.copy") + " " + t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")} getPopupContainer={() => document.body}>
												<div className="form-copy-button" onClick={() => copyToClipboard(terminalConfigs.secret)}>
													<i className="icon-copy"></i>
												</div>
											</Tooltip>
										</Col>
										<FormItem shouldUpdate noStyle>
											{({ getFieldValue }) => (
												<Col span={12}>
													<FormItem
														label={`${t("pages.dashboard.partners.terminal_settings.terminal_configs.endpoint_url")} *`}
														name={FORM_FIELDS.ENDPOINT_URL}
														rules={[
															{
																validator: (_, value) => {
																	if (isNullish(value) || value === "") {
																		return Promise.reject(t("validation.field_required"));
																	}
																	if (!URL_REGEX.test(value)) {
																		return Promise.reject(t("validation.url_format"));
																	}
																	return Promise.resolve();
																}
															}
														]}
													>
														<Input
															disabled={isDisabled}
															placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.terminal_settings.terminal_configs.endpoint_url")}`}
														/>
													</FormItem>
												</Col>
											)}
										</FormItem>
									</Row>
								</Card>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h4>{t("pages.dashboard.partners.terminal_settings.terminal_configs.ticket_redemption")}</h4>
								<Row gutter={[16, 0]}>
									<Col xs={24} sm={24} md={8} lg={8} xl={6}>
										<FormItem label={t("pages.dashboard.partners.terminal_settings.terminal_configs.redemption_date")} name="ticketRedemptionPeriod">
											<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled}>
												<Select.Option value={REDEMPTION_DATE_VALUES.NEVER}>{t("common.never")}</Select.Option>
												<Select.Option value={REDEMPTION_DATE_VALUES.SEVEN_DAYS}>{`7 ${t("common.days")}`}</Select.Option>
												<Select.Option value={REDEMPTION_DATE_VALUES.FORTEEN_DAYS}>{`14 ${t("common.days")}`}</Select.Option>
												<Select.Option value={REDEMPTION_DATE_VALUES.THIRTHY_DAYS}>{`30 ${t("common.days")}`}</Select.Option>
												<Select.Option value={REDEMPTION_DATE_VALUES.FORTYFIVE_DAYS}>{`45 ${t("common.days")}`}</Select.Option>
												<Select.Option value={REDEMPTION_DATE_VALUES.SIXTY_DAYS}>{`60 ${t("common.days")}`}</Select.Option>
												<Select.Option value={REDEMPTION_DATE_VALUES.NINTY_DAYS}>{`90 ${t("common.days")}`}</Select.Option>
												<Select.Option value={REDEMPTION_DATE_VALUES.HUNDREDTWENTY_DAYS}>{`120 ${t("common.days")}`}</Select.Option>
											</Select>
										</FormItem>
									</Col>
								</Row>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<div className="inline-form-item form-switcher form-item-without-margin">
									<label>{t("pages.dashboard.partners.retail_settings.retail_configs.send_additional_data")}</label>
									<FormItem name="sendAdditionalData" valuePropName="checked" className="ant-row">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name">{t("pages.dashboard.bets.combo_boost")}</h1>
							</Col>
							<Col span={24} className="vs--mb-18">
								<FormItem shouldUpdate noStyle>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["comboBoostSettings", "isComboBoostEnabled"]);
										return (
											<div className="inline-form-item form-switcher form-item-without-margin">
												<label>{t(isComboBoostEnabled ? "common.enabled" : "common.disabled")}</label>
												<FormItem name={["comboBoostSettings", "isComboBoostEnabled"]} valuePropName="checked" className="ant-row">
													<Switch disabled={isDisabled} />
												</FormItem>
											</div>
										);
									}}
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={4}>
								<FormItem noStyle shouldUpdate>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["comboBoostSettings", "isComboBoostEnabled"]);
										return (
											<FormItem
												label={`${t("pages.dashboard.bets.combo_boost_minOddFactor")} *`}
												name={["comboBoostSettings", "minOddFactor"]}
												rules={[
													{
														validator: (_, value) => {
															const numericValue = Number(value) || 0;
															if (!isComboBoostEnabled) {
																return Promise.resolve();
															}
															if (numericValue < 1.01 || numericValue > 1000) {
																return Promise.reject(t("validation.must_be_between").replace("%X%", 1.01).replace("%Y%", 1000));
															}
															return Promise.resolve();
														}
													}
												]}
											>
												<NumericInput decimalsCount={2} placeholder={`${t("common.enter")} ${t("pages.dashboard.bets.combo_boost_minOddFactor")}`} disabled={isDisabled || !isComboBoostEnabled} />
											</FormItem>
										);
									}}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col span={24}>
								<h1 className="dashboard-section-name">
									{`${t("pages.dashboard.bets.boost_factor")} *`}
									<Tooltip
										title={t("pages.dashboard.bets.please_set_boost_factor")}
									>
										<i className="icon-info vs--font-bigest vs--cursor-pointer" />
									</Tooltip>
								</h1>
							</Col>
							<Col span={24}>
								<FormItem noStyle shouldUpdate>
									{({ getFieldValue }) => {
										const isComboBoostEnabled = getFieldValue(["comboBoostSettings", "isComboBoostEnabled"]);
										const anyStepHasValue = comboBoostStepsValues.some(value => {
											const fieldValue = getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]]);
											if (isNullish(fieldValue) || fieldValue === "") {
												return false
											}
											return fieldValue > 0
										})
										return (
											<Row gutter={[16, 0]}>
												{comboBoostStepsValues.map((comboBoostStep) => {
													const name = ["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[comboBoostStep]];
													const previousComboBoostSteps = comboBoostStepsValues.filter(value => value < comboBoostStep)
													const nextComboBoostSteps = comboBoostStepsValues.filter(value => value > comboBoostStep)
													const disabledByValue = (
														previousComboBoostSteps
															.some(value => 100 === Number(getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]])) || 0)
													)
													const disabled = isDisabled || !isComboBoostEnabled || disabledByValue;
													const rules = (
														isComboBoostEnabled
															? (
																[
																	{
																		validator: (_, value) => {
																			const numericValue = Number(value) || 0;
																			if (!anyStepHasValue) {
																				return Promise.reject(t("validation.enter_this_or_other_field"));
																			}
																			if (isNullish(value) || value === "") {
																				return Promise.resolve();
																			}
																			let index = null;
																			const biggerExistInNextSteps = nextComboBoostSteps.some((value, i) => {
																				const nextValue = getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]]);
																				if (isNullish(nextValue) || nextValue === "") {
																					return false
																				}
																				if (numericValue >= Number(nextValue) || 0) {
																					index = i;
																					return true
																				}
																				return false
																			})
																			if (biggerExistInNextSteps) {
																				return Promise.reject(
																					t("validation.must_be_less_and_not_equal_than_other")
																						.replace("%X%", "Field")
																						.replace("%Y%", getFieldValue(["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[nextComboBoostSteps[index]]]))
																				);
																			}
																			if (numericValue < 1 || numericValue > 100) {
																				return Promise.reject(t("validation.must_be_between").replace("%X%", 1).replace("%Y%", 100));
																			}
																			return Promise.resolve();
																		}
																	}
																]
															)
															: []
													)
													return (
														<Col key={comboBoostStep}>
															<FormItem
																name={name}
																label={t(COMBO_BOOST_STEPS_TRANSLATIONS[comboBoostStep])}
																rules={rules}
																dependencies={
																	comboBoostStepsValues
																		.filter(value => value !== comboBoostStep)
																		.map(value => ["comboBoostSettings", "boostFactors", COMBO_BOOST_STEP_FORM_NAMES[value]])
																}
															>
																<NumericInput isInteger={true} placeholder={`${t("common.enter")} 1 - 100%`} disabled={disabled} />
															</FormItem>
														</Col>
													);
												})}
											</Row>
										);
									}}
								</FormItem>
							</Col>
						</Row>
					</div>
					<FormItem className="button-container">
						<Button
							loading={isSaving}
							type="primary"
							htmlType="submit"
							className="button"
							onClick={handleForm}
							disabled={isDisabled || !isFormTouched}
						>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				</div>
			</Form>
		</Spin>
	);
};

export default TerminalSettingsComponent;
