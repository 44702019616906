import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Modal, Select, Tooltip, AutoComplete } from "antd";
const { Item: FormItem } = Form;

import SearchableSelect from "components/common/searchableSelect";
import NumericInput from "components/common/numericInput";
import AutoSuggestionInput from "components/common/autoSuggestionInput";

import { numberTransform, binaryToFlags } from "utils/common";
import { isFormChanged } from "utils/form";
import { favoriteBetsRepeatingIndexes, validateMax, validateMin, validatePosMax } from "utils/currency";

import { PARTNER_TYPE } from "constants/partner.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { RATE_SOURCE_TYPE } from "constants/currency.constants";
import { FAVORITE_BETS, FAVORITE_BET_SUM } from "constants/bet.constants";

import { addPartnerCurrency, updatePartnerCurrency } from "store/actions/dashboard/partners/currencies.action";
import { getSystemAvailableCurrencies } from "store/actions/dashboard/settings/systemCurrencies/systemCurrencies.action";

import currencyType from "types/currency/currency.type";

const partnersFavBets = {
	favoriteBets: Array(4).fill(""),
	retailFavoriteBets: Array(8).fill(""),
	terminalFavoriteBets: Array(8).fill("")
};

/** Partner Currency Adding/Editing Popup Component */
const CurrencyAddComponent = ({ isSaving, addPartnerCurrency, updatePartnerCurrency, onClose, editingCurrency, mode, currencies, partnerType, getSystemAvailableCurrencies, systemCurrencies }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, getFieldValue, setFieldsValue, getFieldError, getFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [defaultRate, setDefaultRate] = useState("");

	const isMinMaxValidationChecking = (value, filedName = "", checkingFieldPostfix = "", compareFn) => {
		const obj = getFieldValue(filedName);
		const multi = obj[`multi${checkingFieldPostfix}`];
		const single = obj[`single${checkingFieldPostfix}`];
		return multi === "" || single === "" || compareFn(value, multi) || compareFn(value, single);
	};

	/** Load system currencies */
	useEffect(() => {
		getSystemAvailableCurrencies();
	}, []);

	/** Get initial values for form
	 * @function
	 * @returns {object} - initial values
	 * @memberOf CurrencyAddComponent
	 */
	const getDefaultValues = () => {
		const emptyBetLimits = {
			singleMin: "",
			singleMax: "",
			multiMin: "",
			multiMax: "",
			singlePossibleWinMax: "",
			multiPossibleWinMax: ""
		};
		const initial = {
			...partnersFavBets,
			code: undefined,
			rate: "",
			decimalCount: 0,
			enabled: true,
			isDefault: false
		};
		initial.onlineBetLimits = { ...emptyBetLimits };
		initial.retailBetLimits = { ...emptyBetLimits };
		initial.terminalBetLimits = { ...emptyBetLimits };

		const result = editingCurrency?.code ? currencies.find(c => c.code === editingCurrency.code) || initial : initial;

		return {
			...result,
			rate: editingCurrency && result.rateSourceType === RATE_SOURCE_TYPE.DEFAULT ? "" : result.rate
		};
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf CurrencyAddComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const systemData = systemCurrencies.find((sac) => sac.code === data.code);
				const defaultData = {
					...getDefaultValues(),
					code: data.code,
					decimalCount: data.decimalCount
				}

				const d = Object.assign({}, defaultData, systemData);

				if (mode === "add") { d.isDefault = currencies.length === 0 }
				if (mode === "edit") { d.enabled = editingCurrency.enabled }
				if (data.favoriteBets) { d.favoriteBets = data.favoriteBets }
				if (data.retailFavoriteBets) { d.retailFavoriteBets = data.retailFavoriteBets }
				if (data.terminalFavoriteBets) { d.terminalFavoriteBets = data.terminalFavoriteBets }

				d.rate = data.rate && data.rate !== defaultRate ? Number(data.rate) : null;

				if (isOnline()) {
					d.onlineBetLimits = data.onlineBetLimits;
				}
				if (isRetail()) {
					d.retailBetLimits = data.retailBetLimits;
				}
				if (isSSBT()) {
					d.terminalBetLimits = data.terminalBetLimits;
				}

				if (mode === "add") {
					addPartnerCurrency(d);
					if (d.favoriteBets === partnersFavBets.favoriteBets) {
						d.favoriteBets = systemCurrencies.find((sac) => sac.code === data.code).favoriteBets;
					}
					if (d.retailFavoriteBets === partnersFavBets.retailFavoriteBets) {
						d.retailFavoriteBets = systemCurrencies.find((sac) => sac.code === data.code).retailFavoriteBets;
					}
					if (d.terminalFavoriteBets === partnersFavBets.terminalFavoriteBets) {
						d.terminalFavoriteBets = systemCurrencies.find((sac) => sac.code === data.code).terminalFavoriteBets;
					}
				} else {
					updatePartnerCurrency(d);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	/** Is retail type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf CurrencyAddComponent
	 */
	const isRetail = () => binaryToFlags(Object.values(PARTNER_TYPE), partnerType).includes(PARTNER_TYPE.RETAIL);

	/** Is online type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf CurrencyAddComponent
	 */
	const isOnline = () => binaryToFlags(Object.values(PARTNER_TYPE), partnerType).includes(PARTNER_TYPE.ONLINE);

	/** Is terminal type partner
	 * @function
	 * @returns {boolean}
	 * @memberOf CurrencyAddComponent
	 */
	const isSSBT = () => binaryToFlags(Object.values(PARTNER_TYPE), partnerType).includes(PARTNER_TYPE.SSBT);

	/** Fires on "Apply to all" button click
	 * @function
	 * @memberOf CurrencyAddComponent
	 */
	const applyToAll = (type) => {

		let min, max, posWin;
		let minIsValid = true, maxIsValid = true, posWinIsValid = true;
		const key = `${type}BetLimits`;

		min = getFieldValue([key, "singleMin"]);
		max = getFieldValue([key, "singleMax"]);
		posWin = getFieldValue([key, "singlePossibleWinMax"]);

		if (getFieldError([key, "singleMin"]).length !== 0) minIsValid = false;
		if (getFieldError([key, "singleMax"]).length !== 0) maxIsValid = false;
		if (getFieldError([key, "singlePossibleWinMax"]).length !== 0) posWinIsValid = false;

		const fields = {};

		if (minIsValid) {
			fields.singleMin = min;
			fields.multiMin = min;
		}
		if (maxIsValid) {
			fields.singleMax = max;
			fields.multiMax = max;
		}

		if (posWinIsValid) {
			fields.singlePossibleWinMax = posWin;
			fields.multiPossibleWinMax = posWin;
		}

		const result = {
			[key]: fields
		};

		setFieldsValue(result);
		formChanged(getFieldsValue());
	};

	/** Detect if form values changes from initial values
	 * @function
	 * @param {object} formValues - form current values
	 * @memberOf CurrencyAddComponent
	 */
	const formChanged = (formValues) => {
		if (mode === "edit") {
			const values = {
				code: getDefaultValues().code,
				rate: getDefaultValues().rate,
				decimalCount: getDefaultValues().decimalCount,
				favoriteBets: [...getDefaultValues().favoriteBets],
				retailFavoriteBets: [...getDefaultValues().retailFavoriteBets],
				terminalFavoriteBets: [...getDefaultValues().terminalFavoriteBets]
			};
			if (isOnline()) {
				values.onlineBetLimits = { ...getDefaultValues().onlineBetLimits };
			}
			if (isRetail()) {
				values.retailBetLimits = { ...getDefaultValues().retailBetLimits };
			}
			if (isSSBT()) {
				values.terminalBetLimits = { ...getDefaultValues().terminalBetLimits };
			}
			setIsFormTouched(isFormChanged({ ...formValues }, values));
		}
	};

	/** Current selected currency */
	const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(getDefaultValues().code);

	/** Fill default values for each currency */
	useEffect(() => {
		if (systemCurrencies.length > 0 && selectedCurrencyCode && !editingCurrency) {
			const selectedCurrency = systemCurrencies.find((c) => c.code.toLowerCase() === selectedCurrencyCode.toLowerCase());
			const values = {
				favoriteBets: selectedCurrency.favoriteBets || getDefaultValues().favoriteBets,
				onlineBetLimits: selectedCurrency.onlineBetLimits || getDefaultValues().onlineBetLimits,
				retailFavoriteBets: selectedCurrency.retailFavoriteBets || getDefaultValues().retailFavoriteBets,
				retailBetLimits: selectedCurrency.retailBetLimits || getDefaultValues().retailBetLimits,
				terminalFavoriteBets: selectedCurrency.terminalFavoriteBets || getDefaultValues().terminalFavoriteBets,
				terminalBetLimits: selectedCurrency.terminalBetLimits || getDefaultValues().terminalBetLimits,
				decimalCount: selectedCurrency.minorUnit || 0
			};
			setFieldsValue(values);
			setDefaultRate(selectedCurrency.rate)
		} else if (systemCurrencies.length > 0 && editingCurrency?.code) {
			const selectedCurrency = systemCurrencies.find(c => c.code.toLowerCase() === editingCurrency.code.toLowerCase());
			setDefaultRate(selectedCurrency.rate);
		}
	}, [selectedCurrencyCode, systemCurrencies]);

	/** Render JSX of bet limits section
	 * @function
	 * @param {string} type - online/retail
	 * @returns {JSX} -
	 * @memberOf CurrencyAddComponent
	 */
	const renderBetLimits = (type) => {
		if (!type) return <Fragment />;
		return (
			<FormItem shouldUpdate>
				{
					() => {
						return (
							<Row>
								<Col span={24}>
									<Row>
										<Col xs={24} sm={12}>
											<div className="ant-modal-section-title">
												<h4>{t(`pages.dashboard.currency.${type}_default_bet_limits`)}</h4>
												<Tooltip title={t("pages.dashboard.currency.apply_to_all")}>
													<i className="icon-reset" style={{ cursor: "pointer" }} onClick={() => applyToAll(type)} />
												</Tooltip>
											</div>
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.min_single")}
												name={[`${type}BetLimits`, "singleMin"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", "0.000001"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMin(value, getFieldValue(`${type}BetLimits`).singleMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.min_single")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue([`${type}BetLimits`, "singleMax"]) !== "") {
																validateFields([[`${type}BetLimits`, "singleMax"]]);
															}
															const nonEmpty = getFieldValue(`${type === "online" ? "favorite" : "retailFavorite"}Bets`)
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => [`${type === "online" ? "favorite" : "retailFavorite"}Bets`, f]));
														}, 0);
													}}
												/>
											</FormItem>
										</Col>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.min_multi")}
												name={[`${type}BetLimits`, "multiMin"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", "0.000001"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMin(value, getFieldValue(`${type}BetLimits`).multiMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.min_multi")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue([`${type}BetLimits`, "multiMax"]) !== "") validateFields([[`${type}BetLimits`, "multiMax"]]);
															const nonEmpty = getFieldValue(`${type === "online" ? "favorite" : "retailFavorite"}Bets`)
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => [`${type === "online" ? "favorite" : "retailFavorite"}Bets`, f]));
														}, 0);
													}}
												/>
											</FormItem>
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.max_single")}
												name={[`${type}BetLimits`, "singleMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", "0.000001"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMax(getFieldValue(`${type}BetLimits`).singleMin, value, getFieldValue(`${type}BetLimits`).singlePossibleWinMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_single")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue([`${type}BetLimits`, "singleMin"]) !== "") validateFields([[`${type}BetLimits`, "singleMin"]]);
															if (getFieldValue([`${type}BetLimits`, "singlePossibleWinMax"]) !== "") validateFields([[`${type}BetLimits`, "singlePossibleWinMax"]]);
															const nonEmpty = getFieldValue(`${type === "online" ? "favorite" : "retailFavorite"}Bets`)
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => [`${type === "online" ? "favorite" : "retailFavorite"}Bets`, f]));
														}, 0);
													}}
												/>
											</FormItem>
										</Col>
										<Col xs={24} sm={12}>
											<FormItem
												label={t("pages.dashboard.currency.max_multi")}
												name={[`${type}BetLimits`, "multiMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", "0.000001"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validateMax(getFieldValue(`${type}BetLimits`).multiMin, value, getFieldValue(`${type}BetLimits`).multiPossibleWinMax);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_multi")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue([`${type}BetLimits`, "multiMin"]) !== "") validateFields([[`${type}BetLimits`, "multiMin"]]);
															if (getFieldValue([`${type}BetLimits`, "multiPossibleWinMax"]) !== "") validateFields([[`${type}BetLimits`, "multiPossibleWinMax"]]);
															const nonEmpty = getFieldValue(`${type === "online" ? "favorite" : "retailFavorite"}Bets`)
																.map((f, i) => (f !== "" ? i : f))
																.filter((f) => f !== "");
															validateFields(nonEmpty.map((f) => [`${type === "online" ? "favorite" : "retailFavorite"}Bets`, f]));
														}, 0);
													}}
												/>
											</FormItem>
										</Col>
									</Row>
									<Row gutter={[16, 0]}>
										<Col xs={24} sm={12}>
											<FormItem
												className="form-item-without-margin"
												label={t("pages.dashboard.currency.max_possible_win_single")}
												name={[`${type}BetLimits`, "singlePossibleWinMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", "0.000001"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validatePosMax(getFieldValue(`${type}BetLimits`).singleMax, value);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_possible_win_single")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue([`${type}BetLimits`, "singleMax"]) !== "") validateFields([[`${type}BetLimits`, "singleMax"]]);
														}, 0);
													}}
												/>
											</FormItem>
										</Col>
										<Col xs={24} sm={12}>
											<FormItem
												className="form-item-without-margin"
												label={t("pages.dashboard.currency.max_possible_win_multi")}
												name={[`${type}BetLimits`, "multiPossibleWinMax"]}
												rules={[
													{ required: true, message: t("validation.field_required") },
													{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", "0.000001"), transform: numberTransform },
													({ getFieldValue }) => ({
														validator(rule, value) {
															return validatePosMax(getFieldValue(`${type}BetLimits`).multiMax, value);
														}
													})
												]}
											>
												<NumericInput
													placeholder={`${t("common.enter")} ${t("pages.dashboard.currency.max_possible_win_multi")}`}
													onChange={() => {
														setTimeout(() => {
															if (getFieldValue([`${type}BetLimits`, "multiMax"]) !== "") validateFields([[`${type}BetLimits`, "multiMax"]]);
														}, 0);
													}}
												/>
											</FormItem>
										</Col>
									</Row>
								</Col>
							</Row>
						)
					}
				}
			</FormItem>
		);
	};

	const isPartnerType = (type, typeName) => {
		return type === typeName
	}

	const renderFavoriteBetsComponents = (
		type,
		obj = {
			key: 0,
			title: "",
			keysArr: [],
			fieldName: "",
			isMinMaxValidCallback: () => ({ isMinValid: false, isMaxValid: false })
		}
	) => {
		return (
			<FormItem shouldUpdate>
				{
					() => {
						return (
							<Fragment>
								<Row>
									<Col xs={24} sm={12}>
										<div className="ant-modal-section-title">
											<h4>{t(obj.title)}</h4>
										</div>
									</Col>
								</Row>
								<Row gutter={[16, 0]} key={obj.key}>
									{obj.keysArr.map((i) => (
										<Col xs={24} sm={6} key={i} style={{ marginTop: 8 }}>
											<FormItem
												name={[obj.fieldName, i]}
												className="form-item-without-margin form-item-without-label"
												rules={[
													{ required: true, message: t("validation.field_required") },
													({ getFieldValue }) => ({
														validator(rule, value) {
															const txt = (
																isPartnerType(type, "retail")
																	? "retailFavorite"
																	: isPartnerType(type, "terminal")
																		? "terminalFavorite"
																		: "favorite"
															);
															if (value.length > 20 || (value !== "" && (isNaN(value) || Number(value) < 0.00001) && !FAVORITE_BETS.includes(value))) {
																return Promise.reject(t("validation.field_invalid"));
															} else if (favoriteBetsRepeatingIndexes(getFieldValue(obj.fieldName)).includes(i)) {
																return Promise.reject(t(`validation.${txt}_bets_no_duplicate`));
															} else if (!FAVORITE_BETS.includes(value) && value !== "") {
																const { isMinValid, isMaxValid } = obj.isMinMaxValidCallback(value);
																if (!isMaxValid) {
																	return Promise.reject(t(`validation.${txt}_bets_max`));
																} else if (!isMinValid) {
																	return Promise.reject(t(`validation.${txt}_bets_min`));
																}
															}
															return Promise.resolve();
														}
													})
												]}
											>
												<AutoComplete
													filterOption={() => true}
													options={FAVORITE_BETS.map(value => ({ value }))}
													type="number"
													showArrow={true}
													suffixIcon={(<i className="icon-down" />)}
													onChange={(value) => {
														if (value === FAVORITE_BET_SUM) {
															const values = formInstance.getFieldsValue();
															const updated = { ...values };
															updated[obj.fieldName] = updated[obj.fieldName].map(v => {
																if (v === FAVORITE_BET_SUM) {
																	const betLimits = values[obj.betLimitsName];
																	const sumValue = Math.min(betLimits.singleMin, betLimits.multiMin);
																	return `+${sumValue}`
																}
																return v
															})
															formInstance.setFieldsValue(updated);
														}

														setTimeout(() => {
															const nonEmpty = getFieldValue(obj.fieldName).map((f, i) => f !== "" ? i : f).filter(f => f !== "")
															validateFields(nonEmpty.map(f => [obj.fieldName, f]))
														}, 0)
													}}
												/>
											</FormItem>
										</Col>
									))}
								</Row>
							</Fragment>
						)
					}
				}
			</FormItem>
		)
	};

	return (
		<Modal
			open={true}
			title={mode === "add" ? t("pages.dashboard.currency.add_new_currency") : t("pages.dashboard.currency.edit_new_currency")}
			cancelText={t("common.cancel")}
			okText={t("common.save")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.BIG}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: !isFormTouched && mode === "edit" }}
			centered
		>
			<Form
				className="dashboard-form"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{
					code: getDefaultValues().code,
					rate: getDefaultValues().rate,
					decimalCount: getDefaultValues().decimalCount,
					favoriteBets: getDefaultValues().favoriteBets,
					retailFavoriteBets: getDefaultValues().retailFavoriteBets,
					terminalFavoriteBets: getDefaultValues().terminalFavoriteBets,
					onlineBetLimits: getDefaultValues().onlineBetLimits,
					retailBetLimits: getDefaultValues().retailBetLimits,
					terminalBetLimits: getDefaultValues().terminalBetLimits
				}}
				onValuesChange={(changed, formValues) => {
					formChanged(formValues);
				}}
			>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={8}>
						<FormItem label={t("pages.dashboard.currency.select_currency")} name="code" rules={[{ required: true, message: t("validation.field_required") }]} className="form-item-without-margin">
							<SearchableSelect
								disabled={mode === "edit"}
								placeholder={t("pages.dashboard.currency.select_currency_placeholder")}
								items={systemCurrencies}
								valueProp={(item) => item.code}
								textProp={(item) => item.name}
								renderText={(item) => item.code.toUpperCase() + " - " + item.name}
								isOptionDisabled={(item) => currencies.filter((c) => c.code.toUpperCase() === item.code.toUpperCase()).length > 0}
								onChange={(v) => {
									setSelectedCurrencyCode(v.toUpperCase());
									if (v.toUpperCase() === "USD") {
										setFieldsValue({ rate: "1" });
									}
								}}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={8}>
						<FormItem
							label={
								<div className="form-item-lable-with-info">
									<span>{`${t("pages.dashboard.currency.rate")}`}</span>
									<Tooltip title={t("pages.dashboard.currency.rate_info")} getPopupContainer={() => document.body}>
										<i className="icon-info" />
									</Tooltip>
								</div>
							}
							name="rate"
							className="form-item-without-margin"
							rules={[
								{ type: "number", max: 10000, message: t("validation.must_be_less").replace("%X%", 10000), transform: numberTransform },
								{ type: "number", min: 0.000001, message: t("validation.must_be_more").replace("%X%", 0.000001), transform: numberTransform }
							]}
						>
							<NumericInput
								placeholder={defaultRate !== "" ? `${t("pages.dashboard.currency.default")}: ${defaultRate}` : `${t("common.enter")} ${t("pages.dashboard.currency.rate")}`}
								disabled={selectedCurrencyCode === "USD"}
								suffix={
									getDefaultValues().rateSourceType !== RATE_SOURCE_TYPE.DEFAULT ? (
										<Tooltip title={t("pages.dashboard.currency.reset_to_default")} getPopupContainer={() => document.body}>
											<i
												className="icon-reset"
												style={{ fontSize: "24px", position: "absolute", right: "8px", cursor: "pointer" }}
												onClick={() => {
													setFieldsValue({ rate: "" });
													formChanged({
														...getFieldsValue(),
														rate: ""
													});
												}}
											/>
										</Tooltip>
									) : undefined
								}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={8}>
						<FormItem label={t("pages.dashboard.currency.decimal_count")} name="decimalCount" className="form-item-without-margin">
							<Select disabled={mode === "edit"} suffixIcon={<i className="icon-down" />}>
								<Select.Option value={0}>0</Select.Option>
								<Select.Option value={1}>0,1</Select.Option>
								<Select.Option value={2}>0,12</Select.Option>
								<Select.Option value={3}>0,123</Select.Option>
								<Select.Option value={4}>0,1234</Select.Option>
								<Select.Option value={5}>0,12345</Select.Option>
								<Select.Option value={6}>0,123456</Select.Option>
								<Select.Option value={7}>0,1234567</Select.Option>
								<Select.Option value={8}>0,12345678</Select.Option>
							</Select>
						</FormItem>
					</Col>
				</Row>

				{isOnline() && renderBetLimits("online")}
				{isRetail() && renderBetLimits("retail")}
				{isSSBT() && renderBetLimits("terminal")}

				{isOnline() &&
					renderFavoriteBetsComponents("online", {
						title: "pages.dashboard.currency.favorite_bets",
						keysArr: [0, 1, 2, 3],
						fieldName: "favoriteBets",
						betLimitsName: "onlineBetLimits",
						isMinMaxValidCallback: (value) => {
							const retVal = { isMinValid: false, isMaxValid: false };
							if (!isOnline()) return retVal;
							if (isMinMaxValidationChecking(value, "onlineBetLimits", "Max", (arg1, arg2) => Number(arg1) <= Number(arg2))) {
								retVal.isMaxValid = true;
							}
							if (isMinMaxValidationChecking(value, "onlineBetLimits", "Min", (arg1, arg2) => Number(arg1) >= Number(arg2))) {
								retVal.isMinValid = true;
							}
							return retVal;
						}
					})}

				{isRetail() &&
					renderFavoriteBetsComponents("retail", {
						title: "pages.dashboard.currency.retail_favorite_bets",
						keysArr: [0, 1, 2, 3, 4, 5, 6, 7],
						fieldName: "retailFavoriteBets",
						betLimitsName: "retailBetLimits",
						isMinMaxValidCallback: (value) => {
							const retVal = { isMinValid: false, isMaxValid: false };
							if (!isRetail()) return retVal;
							if (isMinMaxValidationChecking(value, "retailBetLimits", "Max", (arg1, arg2) => Number(arg1) <= Number(arg2))) {
								retVal.isMaxValid = true;
							}
							if (isMinMaxValidationChecking(value, "retailBetLimits", "Min", (arg1, arg2) => Number(arg1) >= Number(arg2))) {
								retVal.isMinValid = true;
							}
							return retVal;
						}
					})}
				{isSSBT() &&
					renderFavoriteBetsComponents("terminal", {
						title: "pages.dashboard.currency.terminal_favorite_bets",
						keysArr: [0, 1, 2, 3, 4, 5, 6, 7],
						fieldName: "terminalFavoriteBets",
						betLimitsName: "terminalBetLimits",
						isMinMaxValidCallback: (value) => {
							const retVal = { isMinValid: false, isMaxValid: false };
							if (!isSSBT()) return retVal;
							if (isMinMaxValidationChecking(value, "terminalBetLimits", "Max", (arg1, arg2) => Number(arg1) <= Number(arg2))) {
								retVal.isMaxValid = true;
							}
							if (isMinMaxValidationChecking(value, "terminalBetLimits", "Min", (arg1, arg2) => Number(arg1) >= Number(arg2))) {
								retVal.isMinValid = true;
							}
							return retVal;
						}
					})}
			</Form>
		</Modal>
	);
};

/** CurrencyAddComponent propTypes
 * PropTypes
 */
CurrencyAddComponent.propTypes = {
	/** Redux state property, is true when currency is adding */
	isSaving: PropTypes.bool,
	/** Redux action to add partner currecny */
	addPartnerCurrency: PropTypes.func,
	/** Redux action to update partner currecny */
	updatePartnerCurrency: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Current editing currecy */
	editingCurrency: currencyType,
	/** Is Popup opened to edit currecny, or add */
	mode: PropTypes.oneOf(["add", "edit"]),
	/** Redux state property, represents the array of partner currencies  */
	currencies: PropTypes.arrayOf(currencyType),
	/** Redux state, represents the type of current editing partner  */
	partnerType: PropTypes.number,
	/** Redux action to get system available currencies */
	getSystemAvailableCurrencies: PropTypes.func,
	/** Redux state property, represents the array of loaded system currencies */
	systemCurrencies: PropTypes.arrayOf(currencyType)
};

const mapDispatchToProps = (dispatch) => ({
	addPartnerCurrency: (currency) => {
		dispatch(addPartnerCurrency(currency));
	},
	updatePartnerCurrency: (currency) => {
		dispatch(updatePartnerCurrency(currency));
	},
	getSystemAvailableCurrencies: () => {
		dispatch(getSystemAvailableCurrencies());
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partner.isSaving,
		currencies: state.partner.currency.currencies,
		partnerType: state.partner.generalInfo.type,
		systemCurrencies: state.systemCurrencies.systemAvailableCurrencies
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyAddComponent);
