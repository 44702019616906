import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { TICKETS_BEFORE, TICKETS_FINISH, TICKETS_SAVE_BEFORE, TICKETS_SAVE_FINISH, SET_CURRENT_BET_TICKET, SET_CURRENT_CANCEL_TICKET, SET_CURRENT_PAYOUT_TICKET, SET_RETAIL_BET_TICKET, SET_RETAIL_CANCEL_TICKET, SET_RETAIL_PAYOUT_TICKET, SET_TICKET_LOGO, SET_CURRENT_TERMINAL_BET_TICKET, SET_TERMINAL_BET_TICKET, SET_TERMINAL_TICKET_LOGO } from "store/actionTypes";

const setTicketsBefore = () => ({
	type: TICKETS_BEFORE
});

const setTicketsFinished = () => ({
	type: TICKETS_FINISH
});

const setTicketsSaveBefore = () => ({
	type: TICKETS_SAVE_BEFORE
});

const setTicketsSaveFinished = () => ({
	type: TICKETS_SAVE_FINISH
});

export const setCurrentBetTicket = (ticket) => ({
	type: SET_CURRENT_BET_TICKET,
	payload: { ticket }
});

export const setCurrentCancelTicket = (ticket) => ({
	type: SET_CURRENT_CANCEL_TICKET,
	payload: { ticket }
});

export const setCurrentPayoutTicket = (ticket) => ({
	type: SET_CURRENT_PAYOUT_TICKET,
	payload: { ticket }
});

export const setCurrentTerminalBetTicket = (ticket) => ({
	type: SET_CURRENT_TERMINAL_BET_TICKET,
	payload: { ticket }
});

export const setBetTicket = (ticket) => ({
	type: SET_RETAIL_BET_TICKET,
	payload: { ticket }
});

export const setTerminalBetTicket = (ticket) => ({
	type: SET_TERMINAL_BET_TICKET,
	payload: { ticket }
});

export const setCancelTicket = (ticket) => ({
	type: SET_RETAIL_CANCEL_TICKET,
	payload: { ticket }
});

export const setPayoutTicket = (ticket) => ({
	type: SET_RETAIL_PAYOUT_TICKET,
	payload: { ticket }
});

export const setLogo = (ticketLogoId) => ({
	type: SET_TICKET_LOGO,
	payload: { ticketLogoId }
});

export const setTerminalLogo = (ticketLogoId) => ({
	type: SET_TERMINAL_TICKET_LOGO,
	payload: { ticketLogoId }
});

export const getBetTicket = () => {
	return (dispatch) => {
		dispatch(setTicketsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_RETAIL_BET_TICKET}`,
			method: Methods.GET
		})
			.then(({ data: { value: ticket } }) => {
				dispatch(setBetTicket(ticket));
				dispatch(
					setCurrentBetTicket({
						generalDetails: ticket.generalDetails,
						marketSelection: ticket.marketSelection,
						customText: ticket.customText,
						enableBarcodePrinting: ticket.enableBarcodePrinting,
						enableQRPrinting: ticket.enableQRPrinting
					})
				);
				dispatch(setTicketsFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsFinished());
			});
	};
};

export const getTerminalBetTicket = () => {
	return (dispatch) => {
		dispatch(setTicketsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_TERMINAL_BET_TICKET}`,
			method: Methods.GET
		})
			.then(({ data: { value: ticket } }) => {
				dispatch(setTerminalBetTicket(ticket));
				dispatch(
					setCurrentTerminalBetTicket({
						generalDetails: ticket.generalDetails,
						marketSelection: ticket.marketSelection,
						customText: ticket.customText,
						enableBarcodePrinting: ticket.enableBarcodePrinting,
						enableQRPrinting: ticket.enableQRPrinting
					})
				);
				dispatch(setTicketsFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsFinished());
			});
	};
};

export const saveBetTicket = (ticket) => {
	return (dispatch) => {
		dispatch(setTicketsSaveBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_RETAIL_BET_TICKET}`,
			method: Methods.POST,
			data: ticket
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setBetTicket(data.value));
				}
				dispatch(setTicketsSaveFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsSaveFinished());
			});
	};
};

export const saveTerminalBetTicket = (ticket) => {
	return (dispatch) => {
		dispatch(setTicketsSaveBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_TERMINAL_BET_TICKET}`,
			method: Methods.POST,
			data: ticket
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setTerminalBetTicket(data.value));
				}
				dispatch(setTicketsSaveFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsSaveFinished());
			});
	};
};

export const getCancelTicket = () => {
	return (dispatch) => {
		dispatch(setTicketsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_RETAIL_CANCEL_TICKET}`,
			method: Methods.GET
		})
			.then(({ data: { value: ticket } }) => {
				dispatch(setCancelTicket(ticket));
				dispatch(
					setCurrentCancelTicket({
						generalDetails: ticket.generalDetails,
						marketSelection: ticket.marketSelection,
						customText: ticket.customText,
						enableBarcodePrinting: ticket.enableBarcodePrinting
					})
				);
				dispatch(setTicketsFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsFinished());
			});
	};
};

export const saveCancelTicket = (ticket) => {
	return (dispatch) => {
		dispatch(setTicketsSaveBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_RETAIL_CANCEL_TICKET}`,
			method: Methods.POST,
			data: ticket
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setCancelTicket(data.value));
				}
				dispatch(setTicketsSaveFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsSaveFinished());
			});
	};
};

export const getPayoutTicket = () => {
	return (dispatch) => {
		dispatch(setTicketsBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_PARTNER_RETAIL_PAYOUT_TICKET}`,
			method: Methods.GET
		})
			.then(({ data: { value: ticket } }) => {
				dispatch(setPayoutTicket(ticket));
				dispatch(
					setCurrentPayoutTicket({
						generalDetails: ticket.generalDetails,
						marketSelection: ticket.marketSelection,
						customText: ticket.customText,
						enableBarcodePrinting: ticket.enableBarcodePrinting
					})
				);
				dispatch(setTicketsFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsFinished());
			});
	};
};

export const savePayoutTicket = (ticket) => {
	return (dispatch) => {
		dispatch(setTicketsSaveBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SET_PARTNER_RETAIL_PAYOUT_TICKET}`,
			method: Methods.POST,
			data: ticket
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setPayoutTicket(data.value));
				}
				dispatch(setTicketsSaveFinished());
			})
			.catch((ex) => {
				dispatch(setTicketsSaveFinished());
			});
	};
};

export const deleteTicketLogo = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PARTNER_DELETE_RETAIL_TICKET_LOGO}`,
			method: Methods.DELETE
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setLogo(null));
				}
			})
			.catch(Function.prototype);
	};
};

export const deleteTerminalTicketLogo = () => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PARTNER_DELETE_TERMINAL_TICKET_LOGO}`,
			method: Methods.DELETE
		})
			.then(({ data }) => {
				if (data.status === RESPONSE_STATE.SUCCESS) {
					message.success(data.message);
					dispatch(setTerminalLogo(null));
				}
			})
			.catch(Function.prototype);
	};
};