import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Switch, Spin } from "antd";
const { Item: FormItem } = Form;

import NumericInput from "components/common/numericInput";

import { getPartnerTerminalRTP, savePartnerTerminalRTP } from "store/actions/dashboard/partners/terminalSettings.action";

import { isFormChanged } from "utils/form";
import { numberTransform } from "utils/common";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import rtpType from "types/partner/rtp.type";

/** Partner Edit Page Online Tab RTP% subTab Component */
const TerminalRTPComponent = ({ isSaving, isLoading, terminalRTP, getPartnerTerminalRTP, savePartnerTerminalRTP, onTabChange }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, getFieldValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_TERMINAL_RTPS, action: PERMISSION_ACTION.MODIFY });

	/** Load partner online RTP */
	useEffect(() => {
		getPartnerTerminalRTP();
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...terminalRTP
		});
	}, [terminalRTP]);

	/** Fires when form submitted
	 * @function
	 * @memberOf TerminalRTPComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				savePartnerTerminalRTP({
					...data
				});
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				initialValues={{
					allowOverride: terminalRTP.allowOverride,
					minPossibleValue: terminalRTP.minPossibleValue,
					maxPossibleValue: terminalRTP.maxPossibleValue
				}}
				onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...terminalRTP }))}
			>
				<div className="dashboard-section-content">
					<div>
						<Row>
							<Col span={24}>
								<div className="inline-form-item" style={{ marginBottom: "24px" }}>
									<label>{t("pages.dashboard.partners.rtp.allow_rtp_override")}</label>
									<FormItem className="inline-form-item-control" name="allowOverride" valuePropName="checked">
										<Switch disabled={isDisabled} />
									</FormItem>
								</div>
							</Col>
						</Row>
						<Row gutter={[16, 0]}>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={`${t("pages.dashboard.partners.rtp.min_possible_value")} *`}
									name="minPossibleValue"
									validateFirst={true}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 1, message: t("validation.must_be_more").replace("%X%", "1"), transform: numberTransform },
										{ type: "number", max: 50, message: t("validation.must_be_less").replace("%X%", "50"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (getFieldValue("maxPossibleValue") !== "" && Number(value) > getFieldValue("maxPossibleValue")) {
													return Promise.reject(t("validation.must_be_less_than_other").replace("%X%", t("pages.dashboard.partners.rtp.min_possible_value")).replace("%Y%", t("pages.dashboard.partners.rtp.max_possible_value")));
												}
												return Promise.resolve();
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.rtp.min_possible_value")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue("maxPossibleValue") !== "") validateFields(["maxPossibleValue"]);
											}, 0);
										}}
										disabled={isDisabled}
									/>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} xl={6}>
								<FormItem
									label={`${t("pages.dashboard.partners.rtp.max_possible_value")} *`}
									name="maxPossibleValue"
									validateFirst={true}
									rules={[
										{ required: true, message: t("validation.field_required") },
										{ type: "number", min: 1, message: t("validation.must_be_more").replace("%X%", "1"), transform: numberTransform },
										{ type: "number", max: 50, message: t("validation.must_be_less").replace("%X%", "50"), transform: numberTransform },
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (getFieldValue("minPossibleValue") !== "" && Number(value) < getFieldValue("minPossibleValue")) {
													return Promise.reject(t("validation.must_be_more_than_other").replace("%X%", t("pages.dashboard.partners.rtp.max_possible_value")).replace("%Y%", t("pages.dashboard.partners.rtp.min_possible_value")));
												}
												return Promise.resolve();
											}
										})
									]}
								>
									<NumericInput
										placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.rtp.max_possible_value")}`}
										onChange={() => {
											setTimeout(() => {
												if (getFieldValue("minPossibleValue") !== "") validateFields(["minPossibleValue"]);
											}, 0);
										}}
										disabled={isDisabled}
									/>
								</FormItem>
							</Col>
						</Row>
					</div>
					{!isDisabled && (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Spin>
	);
};

/** TerminalRTPComponent propTypes
 * PropTypes
 */
TerminalRTPComponent.propTypes = {
	/** Redux state property, is true when online rtp is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when online rtp is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the online rtp of current editing partner  */
	terminalRTP: rtpType,
	/** Redux action to save partner online rtp */
	savePartnerTerminalRTP: PropTypes.func,
	/** Redux action to get partner online rtp  */
	getPartnerTerminalRTP: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getPartnerTerminalRTP: () => {
		dispatch(getPartnerTerminalRTP());
	},

	savePartnerTerminalRTP: (data) => {
		dispatch(savePartnerTerminalRTP(data));
	}
});

const mapStateToProps = (state) => {
	return {
		isSaving: state.partners.isSaving,
		isLoading: state.partner.isLoading,
		terminalRTP: state.partner.terminalSettings.terminalRTP
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalRTPComponent);
