import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Input, Spin, Radio } from "antd";
const { Item: FormItem } = Form;

import VideoPlayer from "components/common/videoPlayer";
import NanoAndFlussonicForm from "components/dashboard/virtuals/games/edit/sections/streamConfig/nanoAndFlussonicForm";
import OvenPlayerForm from "components/dashboard/virtuals/games/edit/sections/streamConfig/ovenPlayerForm";

import { getGameStreamConfiguration, saveGameStreamConfiguration } from "store/actions/dashboard/virtuals/games/streamConfiguration.action";
import { getPartnerAvailableLanguages } from "store/actions/dashboard/partners/languages.action";

import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { URL_REGEX } from "constants/regex.constants";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import gameStreamConfigurationType from "types/game/streamConfiguration.type";

/** Game Edit Page Stream Config Tab Component */
const StreamConfigComponent = ({ getGameStreamConfiguration, saveGameStreamConfiguration, isSaving, isLoading, streamConfiguration, getPartnerAvailableLanguages, onTabChange }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [streamProvider, setStreamProvider] = useState(null);

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf StreamConfigComponent
	 */
	const formChanged = (formValues) => {
		const values = { ...formValues };

		const streamConfig = { ...streamConfiguration };
		if (values.streamProvider !== GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS && values.streamProvider !== GAME_STREAM_CONFIGURATION_TYPE.FLUSSONIC) {
			delete values.streams;
		}
		if (values.streamProvider === GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER) {
			streamConfig[`stream-${GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER}`] = streamConfig.sources
			delete values.sources;
		}
		if (values[`streams-${streamProvider}`])
			values[`streams-${streamProvider}`] = values[`streams-${streamProvider}`].map((s) => ({
				...s,
				languageCode: s.languageCode || null
			}));
		if (streamConfig.streams)
			streamConfig.streams = streamConfig.streams.map((s) => ({
				...s,
				languageCode: s.languageCode || null
			}));

		return isFormChanged(values, streamConfig);
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf StreamConfigComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				const { [`streams-${streamProvider}`]: streamProviderData, ...rest } = data;

				const d =
				streamProvider === GAME_STREAM_CONFIGURATION_TYPE.WOWZA
						? { ...rest }
						: {
								...rest,
								[streamProvider === GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER ? "sources" : "streams"]: streamProviderData
									? streamProviderData.map((s) => ({
											...s,
											languageCode: s.languageCode || null,
											securityToken: undefined
										}))
									: streamProviderData
							};
				saveGameStreamConfiguration(routeParams.id, d);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	/** Load game stream configuration */
	useEffect(() => {
		getGameStreamConfiguration(routeParams.id);
	}, []);

	/** Load partner languages */
	useEffect(() => {
		getPartnerAvailableLanguages();
	}, []);

	/** Set initial Stream Provider */
	useEffect(() => {
		setFieldsValue({ streamProvider: streamConfiguration.streamProvider });

		if (streamConfiguration.streamProvider !== undefined) {
			setStreamProvider(streamConfiguration.streamProvider);
		}

		if(streamConfiguration.streamProvider === GAME_STREAM_CONFIGURATION_TYPE.WOWZA){
			setFieldsValue({ streamUrl: streamConfiguration.streamUrl });
		}
	}, [streamConfiguration]);

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	const renderWowzaForm = () => (
		<Row gutter={[16, 0]}>
			<Col span={24}>
				<FormItem
					label={`${t("pages.dashboard.games.streamConfigs.streamUrl")} *`}
					name="streamUrl"
					rules={[
						{ required: true, whitespace: true, message: t("validation.field_required") },
						{ pattern: URL_REGEX, message: t("validation.url_format") }
					]}
				>
					<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.games.streamConfigs.streamUrl")}`} readOnly={!hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })} />
				</FormItem>
			</Col>
		</Row>
	);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form colon={false} form={formInstance} requiredMark={false} layout="vertical" validateTrigger="onSubmit" initialValues={{}} onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xl={12}>
								<Row>
									<Col span={24}>
										<h1>{t("pages.dashboard.games.tabs.stream_configs")}</h1>
									</Col>
									<Col span={24}>
										<Col span={24}>
											<FormItem name="streamProvider" className="form-item-radio-group">
												<Radio.Group
													size="large"
													disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })}
													style={{ marginBottom: "8px" }}
													onChange={(e) => setStreamProvider(e.target.value)}
												>
													<Radio value={GAME_STREAM_CONFIGURATION_TYPE.WOWZA}>{t("pages.dashboard.games.streamConfigs.wowza")}</Radio>
													<Radio value={GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS}>{t("pages.dashboard.games.streamConfigs.nanocosmos")}</Radio>
													<Radio value={GAME_STREAM_CONFIGURATION_TYPE.FLUSSONIC}>{t("pages.dashboard.games.streamConfigs.flussonic")}</Radio>
													<Radio value={GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER}>{t("pages.dashboard.games.streamConfigs.ovenplayer")}</Radio>
												</Radio.Group>
											</FormItem>
										</Col>
									</Col>
								</Row>
								<FormItem shouldUpdate>
									{
										() => {
											if (!streamProvider) {
												return null
											}

											if (streamProvider === GAME_STREAM_CONFIGURATION_TYPE.WOWZA) {
												return renderWowzaForm()
											}

											if (streamProvider === GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER) {
												return <OvenPlayerForm streamProvider={streamProvider} setFieldsValue={setFieldsValue} />
											}
											
											return <NanoAndFlussonicForm streamProvider={streamProvider} setFieldsValue={setFieldsValue} />
										}
									}
								</FormItem>
							</Col>
							<Col xs={24} sm={24} xl={12}>
								<FormItem>
									{streamProvider !== null && (
										<VideoPlayer
											streamConfiguration={streamProvider !== GAME_STREAM_CONFIGURATION_TYPE.WOWZA ? streamConfiguration : { ...streamConfiguration, streamUrl: streamConfiguration.streamUrl ? streamConfiguration.streamUrl.replace("[LANG]", "ru") : streamConfiguration.streamUrl }}
											streamProvider={streamConfiguration.streamProvider}
											showFullscreen={true}
										/>
									)}
								</FormItem>
							</Col>
						</Row>
					</div>
					{hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY }) ? (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					) : null}
				</div>
			</Form>
		</Spin>
	);
};

/** StreamConfigComponent propTypes
 * PropTypes
 */
StreamConfigComponent.propTypes = {
	/** Redux action to get game stream configuration*/
	getGameStreamConfiguration: PropTypes.func,
	/** Redux action to save game stream configuration*/
	saveGameStreamConfiguration: PropTypes.func,
	/** Redux state property, is true when stream configuration is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when stream configuration is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing game  */
	streamConfiguration: gameStreamConfigurationType,
	/** Redux state property, is true when loading partner languages */
	isLanguagesLoading: PropTypes.bool,
	/** Redux state property, represents the object of loaded partner languages */
	languages: PropTypes.object,
	/** Redux action to get all languages for partner */
	getPartnerAvailableLanguages: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getGameStreamConfiguration: (id) => {
		dispatch(getGameStreamConfiguration(id));
	},

	saveGameStreamConfiguration: (id, data) => {
		dispatch(saveGameStreamConfiguration(id, data));
	},
	getPartnerAvailableLanguages: () => {
		dispatch(getPartnerAvailableLanguages());
	}
});

const mapStateToProps = (state) => {
	return {
		streamConfiguration: state.games.editingGame.streamConfiguration,
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading,
		isLanguagesLoading: state.partner.language.isAvailableLoading,
		languages: state.partner.language.availableLanguages
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamConfigComponent);
